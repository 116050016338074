<template>
    <div class="row child-component banking-info">
        <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">

            <div class="d-block row col-lg-8 mx-auto">
                <b-form-group>
                    <slot name="label">
                        Did the vendor specify that they do, in fact accept credit cards on the form the vendor completed?
                        <custom-tooltip
                            :content="'If No, you must obtain the ACH Banking Form from the vendor.  If you have that form, please select yes & attach form. If you do not, cancel this request and obtain the information requested.'"></custom-tooltip>
                    </slot>
                    <b-form-radio-group id="credit-card-info" v-model="MediaVendorAmend.IsCreditCardAccepted" name="credit-card-info">
                        <b-form-radio value="true">Yes</b-form-radio>
                        <b-form-radio value="false">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="d-block row col-lg-8 mx-auto" v-if="IsCreditCardAccepted">
                <b-form-group>
                    <slot name="label">
                        Are there any conditions or limitations in accepting payment via credit card for this organization? An example of a limitation might be that
                        the company credit card information must be held on file with the vendor for immediate payment processing or that only AMEX is accepted
                        <custom-tooltip
                            :content="'If No, you must obtain the ACH Banking Form from the vendor.  If you have that form, please select yes & attach form. If you do not, cancel this request and obtain the information requested.'"></custom-tooltip>
                    </slot>
                    <b-form-radio-group id="conditions-limitations"
                        v-model="MediaVendorAmend.AreThereConditionsOrLimitations" name="conditions-limitations">
                        <b-form-radio value="true">Yes</b-form-radio>
                        <b-form-radio value="false">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="d-block row col-lg-8 mx-auto" v-if="AreThereConditionsOrLimitations">
                <b-form-group>
                    <slot name="label">What are the Limitations?</slot>
                    <b-form-input autocomplete="off" id="CreditLimitation" class="omniipclass"
                        v-model="MediaVendorAmend.CreditLimitation" placeholder="What are the Limitations?"></b-form-input>
                </b-form-group>
                <b-form-group label="Did the vendor indicate that they would like to be setup for ACH payments on the external vendor form?"
                    class="timeonly-text">
                    <b-form-radio-group v-model="MediaVendorAmend.HasACHPaymentSetup" id="HasACHPaymentSetup" name="HasACHPaymentSetup">
                        <b-form-radio value="true">Yes</b-form-radio>
                        <b-form-radio value="false">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
                <b-form-group v-if="MediaVendorAmend.HasACHPaymentSetup == 'true'" label="Did the vendor provide a bank letter, invoice or voided check confirming their account information?"
                    class="timeonly-text">
                    <b-form-radio-group v-model="MediaVendorAmend.HasBankLetterInvoice" id="HasBankLetterInvoice" name="HasBankLetterInvoice">
                        <b-form-radio value="true">Yes</b-form-radio>
                        <b-form-radio value="false">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div v-if="MediaVendorAmend.IsBankingInfoChanged && HasBankLetterInvoice" class="d-block row col-lg-8 mx-auto mt-5">
                <div style="margin-top:-34px;">
                    <div class="fieldborder">
                        <label>Bank Name</label>
                        <b-form-input @focus="ResetDirty('BankName')" @blur="SetDirty('BankName')" autocomplete="off"
                            id="bank-name" class="omniipclass" v-model="MediaVendorAmend.BankName"
                            placeholder="Enter Bank Name" :maxlength="99">
                        </b-form-input>
                        <div class="error-message-format"
                            v-if="$v.MediaVendorAmend.BankName.$dirty && !$v.MediaVendorAmend.BankName.required">
                            Required field
                        </div>
                    </div>

                    <div class="fieldborder">
                        <label>BANK COUNTRY</label>
                        <OmniSelect class="pt-0 omniipclass omniindexclass" placeholder="Select Country"
                            name="BankCountry" :showLabel="true" label="BankCountry"
                            v-model="MediaVendorAmend.BankCountry" :options="Countryilst">
                        </OmniSelect>
                    </div>

                    <div class="fieldborder">
                        <label>BANK ADDRESS LINE 1 </label>
                        <b-form-input @focus="ResetDirty('BankAddressLine1')" @blur="SetDirty('BankAddressLine1')"
                            autocomplete="off" id="BankAddress-Line1" class="omniipclass"
                            v-model="MediaVendorAmend.BankAddressLine1" placeholder="Enter Bank Address Line1"
                            :maxlength="99"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.MediaVendorAmend.BankAddressLine1.$dirty && !$v.MediaVendorAmend.BankAddressLine1.required">
                            Required field
                        </div>
                    </div>
                    <div class="fieldborder">
                        <label>BANK ADDRESS LINE 2 </label>
                        <b-form-input @focus="ResetDirty('BankAddressLine2')" @blur="SetDirty('BankAddressLine2')"
                            autocomplete="off" id="BankAddress-Line2" class="omniipclass"
                            v-model="MediaVendorAmend.BankAddressLine2" placeholder="Enter Bank Address Line2"
                            :maxlength="99"></b-form-input>
                    </div>

                    <div class="fieldborder" v-if="IsCountryUSA">
                        <label for="city" class="lbltxt" v-if="IsCountryUSA">CITY</label>
                        <b-form-input @focus="ResetDirty('BankCity')" @blur="SetDirty('BankCity')" autocomplete="off"
                            id="billingCity" class="omniipclass" v-if="IsCountryUSA" v-model="MediaVendorAmend.BankCity"
                            placeholder="Enter City" :maxlength="30"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.MediaVendorAmend.BankCity.$dirty && !$v.MediaVendorAmend.BankCity.required">
                            Required field
                        </div>
                    </div>

                    <div class="row fieldborderomniipclass" v-if="IsCountryUSA">
                        <div class="col-md-8">
                            <label v-if="IsCountryUSA" for="BankState" class="lbltxt">State</label>
                            <OmniSelect class="pt-0 omniindexclass omniindexclass" v-if="IsCountryUSA"
                                placeholder="Select State" name="BankState" :showLabel="true" label="STATE"
                                :showSearch="true" v-model="MediaVendorAmend.BankState" :options="StateList">
                            </OmniSelect>
                        </div>
                        <div class="col-md-4">
                            <label v-if="IsCountryUSA" for="BankZipCode" class="lbltxt">ZIP CODE</label>

                            <b-form-input autocomplete="off" id="BankZipCode" class="" :minlength="5" v-if="IsCountryUSA"
                                style="width: 135px !important" :maxlength="5" v-bind:class="{
                                    redtextbox: (validationtextbox || !$v.MediaVendorAmend.BankZipCode.numeric) && $v.MediaVendorAmend.BankZipCode.$dirty,
                                    notredtextbox: !validationtextbox && $v.MediaVendorAmend.BankZipCode.numeric,
                                }" @blur="SetDirty('BankZipCode')" @focus="ResetDirty('BankZipCode')"
                                @keyup="ValidateZipCode()" v-model="MediaVendorAmend.BankZipCode"
                                placeholder="Enter Zip Code"></b-form-input>
                            <div v-show="validationtextbox && $v.MediaVendorAmend.BankZipCode.$dirty" style="width:135px;"
                                class="error-message-format">
                                Input must be 5 characters
                            </div>
                            <div v-show="!$v.MediaVendorAmend.BankZipCode.numeric && $v.MediaVendorAmend.BankZipCode.$dirty"
                                style="width:135px;" class="error-message-format">
                                This field can only contain numerical values
                            </div>
                        </div>
                    </div>

                    <div class="fieldborder">
                        <label>ACCOUNT NAME </label>
                        <b-form-input @focus="ResetDirty('AccountName')" @blur="SetDirty('AccountName')" autocomplete="off"
                            id="Account-Name" class="omniipclass" v-model="MediaVendorAmend.AccountName"
                            placeholder="Enter Account Name" :maxlength="99"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.MediaVendorAmend.AccountName.$dirty && !$v.MediaVendorAmend.AccountName.required">
                            Required field
                        </div>
                    </div>

                    <div class="fieldborder">
                        <label>ACCOUNT NUMBER </label>
                        <b-form-input @focus="ResetDirty('AccountNumber')" @blur="SetDirty('AccountNumber')"
                            autocomplete="off" id="Account-Number" class="omniipclass"
                            v-model="MediaVendorAmend.AccountNumber" placeholder="Enter Account Number"
                            :maxlength="99"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.MediaVendorAmend.AccountNumber.$dirty && !$v.MediaVendorAmend.AccountNumber.required">
                            Required field
                        </div>
                    </div>
                    <div class="fieldborder" v-if="IsCountryUSA">
                        <label>Routing Number (US Only) </label>
                        <b-form-input @focus="ResetDirty('RoutingNumber')" @blur="SetDirty('RoutingNumber')"
                            autocomplete="off" id="Routing-Number" class="omniipclass"
                            v-model="MediaVendorAmend.RoutingNumber" placeholder="Enter Routing Number (US Only)"
                            :maxlength="99"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.MediaVendorAmend.RoutingNumber.$dirty && !$v.MediaVendorAmend.RoutingNumber.required">
                            Required field
                        </div>
                    </div>
                    <div class="fieldborder" v-if="!IsCountryUSA">
                        <label>Sort/Swift (Non US) </label>
                        <b-form-input @focus="ResetDirty('BankBIC')" @blur="SetDirty('BankBIC')" autocomplete="off"
                            id="Bank-BIC" class="omniipclass" v-model="MediaVendorAmend.BankBIC"
                            placeholder="Enter Sort/Swift (Non US)" :maxlength="99"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.MediaVendorAmend.BankBIC.$dirty && !$v.MediaVendorAmend.BankBIC.required">
                            Required field
                        </div>
                    </div>
                    <div class="fieldborder" v-if="!IsCountryUSA">
                        <label>IBAN (Non US)</label>
                        <b-form-input @focus="ResetDirty('BankIBAN')" @blur="SetDirty('BankIBAN')" autocomplete="off"
                            id="Bank-IBAN" class="omniipclass" v-model="MediaVendorAmend.BankIBAN"
                            placeholder="Enter IBAN (Non US)" :maxlength="99"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.MediaVendorAmend.BankIBAN.$dirty && !$v.MediaVendorAmend.BankIBAN.required">
                            Required field
                        </div>
                    </div>
                    <div class="d-block fieldborder">
                        <label>Remittance Advice Email</label>
                        <b-form-input @focus="ResetDirty('RemittanceAdviceEmail')" @blur="SetDirty('RemittanceAdviceEmail')"
                            autocomplete="off" id="RemittanceAdvice-Email" class="omniipclass"
                            v-model="MediaVendorAmend.RemittanceAdviceEmail" placeholder="Enter Remittance Advice Email"
                            :maxlength="99"></b-form-input>
                        <div class="error-message" v-if="!$v.MediaVendorAmend.RemittanceAdviceEmail.email">
                            Please enter a valid email address (eg. test@FinSys.com)
                        </div>
                    </div>
                    <div class="d-block fieldborder mb-n4">
                        <slot name="label" class="lbltxt">
                            <span class="lbltxt" style="margin-left: 11px">Please attach bank letter, invoice, or voided check.</span>
                        </slot>
                        <vue-dropzone v-on:vdropzone-mounted="mountedBankLetterDropzone" :duplicateCheck="true"
                            ref="BankLetterDropzone" id="BankLetterDropzone" :useCustomSlot="true"
                            class="customdropzone" :include-styling="false" :options="dropzoneOptions"
                            v-on:vdropzone-file-added="BankLetterUploadedToDropzone"
                            v-on:vdropzone-removed-file="BankLetterRemovedFromDropzone">
                            <div class="dropzone-custom-content">
                                <p class="Drag-and-drop-file-h">
                                    <b-img style="
                                    margin-top: -7px !important;
                                    font-family: 'Open Sans';" />
                                    Drag and drop file here or
                                    <span class="draganddropspan"> browse </span>

                                </p>
                            </div>
                        </vue-dropzone>
                    </div>
                </div>
            </div>
            <div class="d-block row col-lg-8 mx-auto" v-if="IsCreditCardNotAccepted">
                <b-form-group>
                    <slot name="label">Would the vendor like to be set up by ACH Payment?</slot>
                    <b-form-radio-group id="IsACHPayment" v-model="MediaVendorAmend.IsACHPayment"
                        name="IsACHPayment">
                        <b-form-radio value="true">Yes</b-form-radio>
                        <b-form-radio value="false">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="d-block row col-lg-8 mx-auto" v-if="ShowBankingInfo">
                <b-form-group>
                    <slot name="label">
                        You must obtain the ACH banking Form/Information from the vendor. Have you collected this
                        information?
                    </slot>
                    <b-form-radio-group id="banking-info-collection" v-model="MediaVendorAmend.IsBankingInfoCollected"
                        name="banking-info-collected">
                        <b-form-radio value="true">Yes</b-form-radio>
                        <b-form-radio value="false">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="d-block row col-lg-8 mx-auto" v-if="ShowBankingAttachment">
                <label>Attach ACH Banking Info</label>
                <vue-dropzone v-on:vdropzone-mounted="mountedmyVueDropzone" :duplicateCheck="true" ref="myVueDropzone"
                    id="dropzone" :useCustomSlot="true" class="customdropzone" :include-styling="false"
                    :options="dropzoneOptions" v-on:vdropzone-file-added="UploadedToDropzone"
                    v-on:vdropzone-removed-file="RemovedFromDropzone">
                    <div class="dropzone-custom-content">
                        <p class="Drag-and-drop-file-h">
                            <b-img :src="closeFilterIcon" style="
                        margin-top: -7px !important;
                        font-family: 'Open Sans';" />
                            Drag and drop file here or
                            <span class="draganddropspan"> browse </span>
                        </p>
                    </div>
                </vue-dropzone>
            </div>
        </div>
        <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
            <button class="btn btn-link text-uppercase" @click="Cancel()">
                CANCEL
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Previous()">
                Previous
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Next()"
                :disabled="this.$v.$invalid || forminvalid()">
                NEXT
            </button>
        </div>
        <b-modal v-model="ShowPaymentInfo" hide-footer :no-close-on-backdrop="true">
            <div>
                <p>If no, vendor will be paid by Check to the address provided on the Invoice.</p>
            </div>
            <template v-slot:modal-header="{ close }">
                <h5 class="modal-title">Note</h5>
                <button class="close" @click="CancelPaymentInfo()"></button>
            </template>

            <div class="text-right">
                <button class="text-uppercase btn custom-button btn-primary" @click="CancelPaymentInfo()">
                    Ok
                </button>
            </div>
        </b-modal>

    </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { required, requiredIf, minLength, helpers, numeric } from "vuelidate/lib/validators";
import {
    mapGetters
} from "vuex";
import service from "../../../services/Dropdowns";
const EmailRegEx = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[x01-x08x0bx0cx0e-x1fx21x23-x5bx5d-x7f]|[x01-x09x0bx0cx0e-x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[x01-x08x0bx0cx0e-x1fx21-x5ax53-x7f]|\\[x01-x09x0bx0cx0e-\x7f])+)\])$/
const EmailWithoutCase = helpers.regex('EmailWithoutCase', EmailRegEx)

export default {
    components: {
        vueDropzone: vue2Dropzone,
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            CreditCardFilesLoadedInEdit: false,
            CountryUnitedStatesValue: "USA - United States",
            FilesUploaded: false,
            IsBankLetterLoadedInEdit: false,
            ShowPaymentInfo: false,
            deleteIcon: require("@/assets/Icons/delete.svg"),
            closeFilterIcon: require("../../../assets/Icons/file.png"),
            dropzoneOptions: {
                paramName: "file",
                //params: {'param_1':'xyz','para_2': localid },
                previewTemplate: this.template(),
                url: `${process.env.VUE_APP_API_ROOT}api/v1/mdm/Upload`,
                uploadMultiple: true,
                maxFiles: 5,
                minFiles: 1,
                maxFilesize: 10,
                addRemoveLinks: false,
                parallelUploads: 5,
                createImageThumbnails: false,
                autoProcessQueue: false,
                //  headers:{"Authorization":'Bearer eyJraWQiOiJRS2V5YUxEY2RuQm9YKzlibm4rTGJQbjQrMVhiMjFuR2hHQ0l2aHpPQmJrPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoicldwdUNRbV9PenBvZjhwUG1iOFg4QSIsInN1YiI6IjgzNWU1NGQxLWY4YzMtNDJiYy1hZGQ2LTI4Y2RmZjdiNTc4ZiIsImNvZ25pdG86Z3JvdXBzIjpbImV1LXdlc3QtMV9yemluaUtwNzJfT01HLUFERlMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfcnppbmlLcDcyIiwiY29nbml0bzp1c2VybmFtZSI6Ik9NRy1BREZTX2NoYW5kcnUucG9ubnVzYW15QGFubmFsZWN0LmNvbSIsImdpdmVuX25hbWUiOiJDaGFuZHJ1IiwiYXVkIjoiNzM3MGlzOXZnb2Ewb2g2OXR2MHY2dDAxdXUiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJjaGFuZHJ1LnBvbm51c2FteUBhbm5hbGVjdC5jb20iLCJwcm92aWRlck5hbWUiOiJPTUctQURGUyIsInByb3ZpZGVyVHlwZSI6IlNBTUwiLCJpc3N1ZXIiOiJodHRwOlwvXC9vbWdzc28ub25lb21nLmNvbVwvYWRmc1wvc2VydmljZXNcL3RydXN0IiwicHJpbWFyeSI6InRydWUiLCJkYXRlQ3JlYXRlZCI6IjE2MDE5OTU4MzIxMTEifV0sInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjA3MzM4MjM0LCJleHAiOjE2MDkzMDg5MzUsImlhdCI6MTYwOTMwNTMzNSwiZmFtaWx5X25hbWUiOiJQb25udXNhbXkifQ.A66H0Vuv-pxNRKKOvG6BXsu4bK-Q0NfI5ORK8A8F8Vc59-RwHnuGc4tOL9TZT215YGA8kAh3o4WVs0YMFwXX5hmt7jKOCKKQyCjDhoLMPKNDVZ5eWULEoXVqHky5ZewvVc5y3Evo4rLsfHnyT1OC4j24oc8lwFFOshnqGGzkVNQ8zE4z39MdlioC9zQVxSzaxbAbnyol5qHQWQMXFhLHZOlrQ_5qxYirGdeZd0W7gdY6W8Asqj6thQ37tLf9537B6H54UKPxVeQYzxazbgsWqOFYs8jcMVTxI8dEIK25V-LgPf5Lba3BhqqaVGU_kVcPHH0qzmtRr38tfMIsZi-2Bg'},
                // headers: { "My-Awesome-Header": "header value", GUID: localStorage.getItem("UploadfileId") },
                init: function () {
                    this.on("addedfile", function (file) {
                        if (this.files.length > 5) {
                            this.removeFile(file);
                            window.alert("Maximum File Upload limit is 5!");
                        }
                    });

                    this.on("removedfile", function () { });
                },
            },
            ddl: 0,
            Countryilst: [],
            StateList: [],
            validationtextbox: false,
        }
    },
    validations: {
        MediaVendorAmend: {
            IsCreditCardAccepted: {
                required: required
            },
            IsBankingInfoCollected: {
                required: requiredIf(function (model) {
                    return model.IsACHPayment == "true" || model.IsACHPayment == true;
                })
            },
            CreditLimitation: {
                required: requiredIf(function (model) {
                    return model.AreThereConditionsOrLimitations == "true" || model.AreThereConditionsOrLimitations == true;
                })
            },
            HasACHPaymentSetup: {
                required: requiredIf(function (model) {
                    return model.AreThereConditionsOrLimitations == "true" || model.AreThereConditionsOrLimitations == true;
                }),
            },
            HasBankLetterInvoice: {
                required: requiredIf(function (model) {
                    return model.HasACHPaymentSetup == "true" || model.HasACHPaymentSetup == true;
                }),
            },
            IsACHPayment: {
                required: requiredIf(function (model) {
                    return model.IsCreditCardAccepted == "false" || model.IsCreditCardAccepted == false;
                })
            },
            CreditCardFiles: {
                minLength: minLength(1),
                required: requiredIf(function (model) {
                    return this.MediaVendorAmend.IsBankingInfoCollected == 'true' || this.MediaVendorAmend.IsBankingInfoCollected == true && model.AreThereConditionsOrLimitations == "true" || model.AreThereConditionsOrLimitations == true;
                })
            },
            AreThereConditionsOrLimitations: {
                required: requiredIf(function (model) {
                    return model.IsCreditCardAccepted == "true" || model.IsCreditCardAccepted == true;
                })
            },
            BankCountry: {
                required: requiredIf(function (model) {
                    return this.MediaVendorAmend.IsBankingInfoChanged && model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                })
            },
            BankName: {
                required: requiredIf(function (model) {
                    return this.MediaVendorAmend.IsBankingInfoChanged && model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                })
            },
            BankAddressLine1: {
                required: requiredIf(function (model) {
                    return this.MediaVendorAmend.IsBankingInfoChanged && model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                })
            },
            BankCity: {
                required: requiredIf(function (model) {
                    return model.BankCountry == this.CountryUnitedStatesValue && this.MediaVendorAmend.IsBankingInfoChanged && model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                }),
            },
            BankState: {
                required: requiredIf(function (model) {
                    return model.BankCountry == this.CountryUnitedStatesValue && this.MediaVendorAmend.IsBankingInfoChanged && model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                }),
            },
            BankZipCode: {
                required: requiredIf(function (model) {
                    return model.BankCountry == this.CountryUnitedStatesValue && this.MediaVendorAmend.IsBankingInfoChanged && model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                }),
                numeric,
            },
            AccountName: {
                required: requiredIf(function (model) {
                    return this.MediaVendorAmend.IsBankingInfoChanged && model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                })
            },
            AccountNumber: {
                required: requiredIf(function (model) {
                    return this.MediaVendorAmend.IsBankingInfoChanged && model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                }),
                numeric,
            },
            BankBIC: {
                required: requiredIf(function (model) {
                    return model.BankCountry != this.CountryUnitedStatesValue && this.MediaVendorAmend.IsBankingInfoChanged && model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                }),
            },
            BankIBAN: {
                required: requiredIf(function (model) {
                    return model.BankCountry != this.CountryUnitedStatesValue && this.MediaVendorAmend.IsBankingInfoChanged && model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                }),
            },
            RoutingNumber: {
                required: requiredIf(function (model) {
                    return model.BankCountry == this.CountryUnitedStatesValue && this.MediaVendorAmend.IsBankingInfoChanged && model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                }),
                numeric,
            },
            RemittanceAdviceEmail: {
                required: requiredIf(function (model) {
                return this.MediaVendorAmend.IsBankingInfoChanged && model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                }),
                email: EmailWithoutCase
            },
            BankLetter: {
                minLength: minLength(1),
                required: requiredIf(function (model) {
                    return this.MediaVendorAmend.IsBankingInfoChanged && model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                })
            },
            // AddressLine2: {
            //     required: requiredIf(function (model) {
            //         return (
            //             !this.ShowAddProduct &&
            //             model.BankCountry != "78d70193e1eb493dafcf3f261b7759ef"
            //         );
            //     }),
            // },
            // StreetAddress: {
            //     required: requiredIf(function (model) {
            //         return (
            //             !this.ShowAddProduct &&
            //             model.BankCountry == this.CountryUnitedStatesValue
            //         );
            //     }),
            // },
        }
    },
    mounted() {
        this.LoadDropdownValues();
    },
    computed: {
        HasBankLetterInvoice() {
            return this.MediaVendorAmend.HasBankLetterInvoice == 'true' || this.MediaVendorAmend.HasBankLetterInvoice == true;
        },
        AreThereConditionsOrLimitations() {
            return this.MediaVendorAmend.AreThereConditionsOrLimitations == 'true' || this.MediaVendorAmend.AreThereConditionsOrLimitations == true;
        },
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        ...mapGetters('vendormediaamend', {
            MediaVendorAmend: 'MediaVendorAmend',
        }),
        IsCreditCardAccepted() {
            return this.MediaVendorAmend.IsCreditCardAccepted == 'true' || this.MediaVendorAmend.IsCreditCardAccepted == true;
        },
        IsCountryUSA() {
            return this.MediaVendorAmend.BankCountry != null ?
                this.MediaVendorAmend.BankCountry == this.CountryUnitedStatesValue :
                null;
        },
        IsCreditCardNotAccepted() {
            return this.MediaVendorAmend.IsCreditCardAccepted == 'false' || this.MediaVendorAmend.IsCreditCardAccepted == false;
        },
        IsErrorMessage() {
            return this.IsCreditCardNotAccepted && this.IsBankingInfoNotCollected;
        },
        ShowBankingInfo() {
            return (this.MediaVendorAmend.IsACHPayment == 'true' || this.MediaVendorAmend.IsACHPayment == true) && (this.MediaVendorAmend.IsCreditCardAccepted == 'false' || this.MediaVendorAmend.IsCreditCardAccepted == false);
        },
        ShowBankingAttachment() {
            return (this.MediaVendorAmend.IsBankingInfoCollected == 'true' || this.MediaVendorAmend.IsBankingInfoCollected == true) && (this.MediaVendorAmend.IsACHPayment == 'true'|| this.MediaVendorAmend.IsACHPayment == true);
        },
    },
    watch: {
        "MediaVendorAmend.HasACHPaymentSetup": function (val) {
            if (val == false || val == "false") {
                this.ShowPaymentInfo = true;
                this.MediaVendorAmend.HasBankLetterInvoice = null;
            }
        },
        "MediaVendorAmend.HasBankLetterInvoice": function (val) {
            if (val == false || val == "false" || !val) {
                this.ShowPaymentInfo = true;
                this.MediaVendorAmend.BankName = '';
                this.MediaVendorAmend.BankCountry = '';
                this.MediaVendorAmend.BankAddressLine1 = '';
                this.MediaVendorAmend.BankAddressLine2 = '';
                this.MediaVendorAmend.BankCity = '';
                this.MediaVendorAmend.BankState = '';
                this.MediaVendorAmend.BankZipCode = '';
                this.MediaVendorAmend.AccountName = '';
                this.MediaVendorAmend.AccountNumber = '';
                this.MediaVendorAmend.RoutingNumber = '';
                this.MediaVendorAmend.BankBIC = '';
                this.MediaVendorAmend.BankIBAN = '';
                this.MediaVendorAmend.RemittanceAdviceEmail = '';
            }
        },
        "MediaVendorAmend.AreThereConditionsOrLimitations": function (val) {
            if (val == false || val == 'false' || !this.IsCreditCardAccepted) {
                this.MediaVendorAmend.CreditLimitation = '';
                this.MediaVendorAmend.HasACHPaymentSetup = null;
                this.MediaVendorAmend.HasBankLetterInvoice = null;
            }
        },
        "MediaVendorAmend.VendorTypeSystem": function (val) {
            if(val) {
                this.MediaVendorAmend.VendorRemittanceName = '';
                this.MediaVendorAmend.StreetAddressLine1 = '';
            }
        },
        "MediaVendorAmend.BankCountry": function (val) {
            if(val == this.CountryUnitedStatesValue)  {
                this.MediaVendorAmend.BankBIC = '';
                this.MediaVendorAmend.BankIBAN = '';
            }
            else{
                this.MediaVendorAmend.BankState = '';
                this.MediaVendorAmend.BankCity = '';
                this.MediaVendorAmend.BankZipCode = '';
                this.MediaVendorAmend.RoutingNumber = '';
            }
        },
        // "MediaVendorAmend.IsEditDataLoaded": function (val) {
        //     if (this.IsEdit == true && val == true) {
        //         this.ManuallyUploadFiles(this.MediaVendorAmend.CreditCardFiles);
        //     }
        // },
        "MediaVendorAmend.IsEditDataLoaded": function (val) {
            if (this.IsEdit == true && val == true) {
                if (this.CreditCardFilesLoadedInEdit == false) {
                    this.ManuallyUploadFiles(this.MediaVendorAmend.CreditCardFiles);
                    this.$set(this.MediaVendorAmend, "CreditCardFiles", this.GetFilesObject());
                }
            }
        },
        "MediaVendorAmend.IsCreditCardAccepted": function (val) {
            if(val == false || val == "false") {
                this.MediaVendorAmend.CreditLimitation = '';
                this.MediaVendorAmend.AreThereConditionsOrLimitations = null;
            }
            if (val == true || val == "true") {
                this.$set(this.MediaVendorAmend, "IsBankingInfoCollected", null);
                this.$set(this.MediaVendorAmend, "IsACHPayment", null);
            }
        },
        "MediaVendorAmend.IsACHPayment": function (val) {
            if (val == false || val == "false") {
                this.ShowPaymentInfo = true;
            }
            if (val == true || val == "true") {
                this.$set(this.MediaVendorAmend, "IsBankingInfoCollected", null);
            }

        },
        "MediaVendorAmend.IsBankingInfoCollected": function (val) {
            if (val == false || val == "false") {
                this.ShowPaymentInfo = true;
            }
        }
    },

    methods: {
        mountedmyVueDropzone() {
            if (this.MediaVendorAmend.IsEditDataLoaded == true && this.IsEdit && this.CreditCardFilesLoadedInEdit == false) {
                this.ManuallyUploadFiles(this.MediaVendorAmend.CreditCardFiles);
                this.$set(this.MediaVendorAmend, "CreditCardFiles", this.GetFilesObject());
            }
        },
        ManuallyUploadFiles(files) {
            if (files != null && files.length > 0) {
                files.forEach((fileUploaded2) => {
                    let file = {
                        size: fileUploaded2.Length,
                        name: fileUploaded2.Name,
                        type: fileUploaded2.Extension,
                    };
                    var url = fileUploaded2.FullName;
                    this.$refs.myVueDropzone.manuallyAddFile(file, url);
                });

            }
            this.CreditCardFilesLoadedInEdit = true;
        },
        UploadedToDropzone() {
            this.$set(this.MediaVendorAmend, "CreditCardFiles", this.GetFilesObject());
        },
        RemovedFromDropzone() {
            this.$set(this.MediaVendorAmend, "CreditCardFiles", this.GetFilesObject());
        },
        GetFilesObject() {
            let files = [];
            if (typeof this.$refs.myVueDropzone != "undefined" &&
                typeof this.$refs.myVueDropzone.dropzone != "undefined" &&
                this.$refs.myVueDropzone.dropzone.files.length > 0) {
                let filesExists = this.$refs.myVueDropzone.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name, file: filesExists[i]
                    });
                }
            }

            return files;
        },
        mountedBankLetterDropzone() {
            if (this.MediaVendorAmend.IsEditDataLoaded == true && this.IsEdit && this.IsBankLetterLoadedInEdit == false) {
                this.ManuallyUploadFilesBankLetter(this.MediaVendorAmend.BankLetter);
                this.$set(this.MediaVendorAmend, "BankLetter", this.GetFilesObjectBankLetter());
            }
        },
        CancelPaymentInfo() {
            this.ShowPaymentInfo = false;
        },
        BankLetterUploadedToDropzone() {
            this.WrittenAttachmentFile = this.$refs.BankLetterDropzone.dropzone.files.length;
            this.$set(this.MediaVendorAmend, "BankLetter", this.GetFilesObjectBankLetter());
        },
        BankLetterRemovedFromDropzone() {
            this.WrittenAttachmentFile = this.$refs.BankLetterDropzone.dropzone.files.length;
            this.$set(this.MediaVendorAmend, "BankLetter", this.GetFilesObjectBankLetter());
        },
        GetFilesObjectBankLetter() {
            let files = [];
            if (this.$refs.BankLetterDropzone.dropzone.files && this.$refs.BankLetterDropzone.dropzone.files.length > 0) {
                let filesExists = this.$refs.BankLetterDropzone.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name,
                        file: filesExists[i]
                    });
                }
                return files;
            } else {
                return files;
            }
        },
        ManuallyUploadFilesBankLetter(files) {
            if (files != null && files.length > 0) {
                files.forEach((fileUploaded) => {
                    let file = {
                        size: fileUploaded.Length,
                        name: fileUploaded.Name,
                        type: fileUploaded.Extension,
                    };
                    var url = fileUploaded.FullName;
                    this.$refs.BankLetterDropzone.manuallyAddFile(file, url);
                });

            }
            this.IsWrittenAttachmentLoadedInEdit = true;
        },
        template: function () {
            return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:79px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
        },
        SetDirty(propertyName) {
            this.$v.MediaVendorAmend[propertyName].$touch();

        },
        ResetDirty(propertyName) {
            this.$v.MediaVendorAmend[propertyName].$reset();
        },
        forminvalid() {
            return false;
        },
        ValidateZipCode() {
            if (
                this.BankZipCode && this.BankZipCode.length != 0 &&
                this.BankZipCode.length < 5
            ) {
                this.validationtextbox = true;
            } else {
                this.validationtextbox = false;
            }
        },
        LoadDropdownValues() {
            this.LoadStates();
            service
                .getEntityRecords("Markets")
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.Countryilst = res.data.sort((a, b) =>
                            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                        );
                        let indexUSA = this.Countryilst.findIndex(
                            (i) => i.label == this.CountryUnitedStatesValue
                        );
                        let indexCanada = this.Countryilst.findIndex(
                            (i) => i.label == "CAN - Canada"
                        );
                        if (indexCanada > -1)
                            this.Countryilst.unshift(
                                this.Countryilst.splice(indexCanada, 1)[0]
                            );
                        if (indexUSA > -1)
                            this.Countryilst.unshift(this.Countryilst.splice(indexUSA, 1)[0]);
                        this.Countryilst.forEach(element => { element.value = element.label;});
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        },
        async LoadStates() {
            service
                .getEntityRecords("States")
                .then((res) => {
                    this.StateList = res.data;
                    this.StateList.forEach(element => { element.value = element.label;});
                })
                .catch((err) => {
                    alert(err);
                });
        },
        Next() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
            if (this.id) {
                this.$router.push({
                    name: "Media Vendor Amend Employee Relationship",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Media Vendor Amend Employee Relationship"
                });
            }
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },
        Previous() {
            if (this.id) {
                this.$router.push({
                    name: "Media Vendor Amend Setup Info",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
            });
            } else {
                this.$router.push({
                    name: "Media Vendor Amend Setup Info"
                });
            }
        },
    },

    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    },
}
</script>

<style>
.banking-info .floating-label-input div+label {
    visibility: hidden !important;
}

.banking-info fieldset.form-group:not(.custom-radio)>legend {
    padding-left: 0px !important;
}

/*file upload starts*/

.banking-info .customdropzone {
    width: 411px !important;
    height: 100% !important;
    border-radius: 8px !important;
    border: dashed 2px #e9eef0 !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
}

.banking-info .Drag-and-drop-file-h {
    margin-left: 18px !important;
    margin-top: 14px !important;
    width: 237px !important;
    height: 10px !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 0.71 !important;
    letter-spacing: normal !important;
    color: #a2a9ad !important;
}

.banking-info .draganddropspan {
    color: #00a1d2;
}

.banking-info .error-message {
    padding-top: 10px;
    padding-bottom: 5px;
    height: 14px !important;
    font-size: 12px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #df5678 !important;
}

.banking-info .floating-label-input {
    margin-top: 0px !important;
}

.banking-info .fieldborder {
    padding-bottom: 10px !important;
}

.banking-info .omniipclass {
    /* width: 415px !important; */
    height: 35px !important;
    border: #e9eef0 !important;
    /* style="width:415px; height:35px;" */
}

.banking-info .Text {
    width: 250px;
    height: 196px;
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.banking-info .Text .text-style-1 {
    font-weight: 600;
}



/*file upload ends*/
</style>
